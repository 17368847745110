import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/SEO"

import Button from "components/CustomButtons/Button.jsx";

import {Link} from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <main className="error-page">
      <div className="container">
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <Link to="/"><Button color= "success">Go Back Home</Button></Link>

      </div>
    </main>

  </Layout>
)

export default NotFoundPage
